var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"filter-container"},[_vm._m(0),_c('div',{staticClass:"right-content"},[_c('div',{staticClass:"filter-actions"},[_c('b-button',{attrs:{"variant":"default icon-left"},on:{"click":_vm.closeEdit}},[_c('i',{staticClass:"icon icon-keyboard icon-rotate-180"}),_vm._v(" Назад к списку ")]),_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){return _vm.save(false)}}},[_vm._v("Сохранить")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.save(true)}}},[_vm._v("Сохранить и закрыть")])],1)])]),_c('div',{staticClass:"form-card"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{staticClass:"bolded",staticStyle:{"height":"100%","align-items":"inherit"},attrs:{"label":"Выбрать показатель"}},[_c('multiselect',{attrs:{"track-by":"name","label":"name","placeholder":"Выбрать показатель","options":_vm.indicatorDict,"searchable":true,"allow-empty":false,"show-labels":false},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [(option.name!==undefined)?[(option.name.length<25)?_c('span',[_vm._v(_vm._s(option.name))]):_c('span',{attrs:{"title":option.name}},[_vm._v(_vm._s(option.name.slice(0, 25) + '...'))])]:_vm._e()]}}]),model:{value:(_vm.curIndic),callback:function ($$v) {_vm.curIndic=$$v},expression:"curIndic"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{staticClass:"bolded",attrs:{"label":"Наименование целевого индикатора по документу СГП на казахском языке"}},[_c('b-form-textarea',{model:{value:(_vm.indicatorCustomNameKz),callback:function ($$v) {_vm.indicatorCustomNameKz=$$v},expression:"indicatorCustomNameKz"}})],1),_c('b-form-group',{staticClass:"bolded",attrs:{"label":"Наименование целевого индикатора по документу СГП на русском языке"}},[_c('b-form-textarea',{model:{value:(_vm.indicatorCustomNameRu),callback:function ($$v) {_vm.indicatorCustomNameRu=$$v},expression:"indicatorCustomNameRu"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Макроиндикатор"}},[_c('b-form-checkbox',{staticStyle:{"margin-left":"60px"},attrs:{"switch":""},model:{value:(_vm.macroindicator),callback:function ($$v) {_vm.macroindicator=$$v},expression:"macroindicator"}})],1),_c('b-form-group',{attrs:{"label":"Вышестоящий док. СГП"}},[_c('multiselect',{attrs:{"track-by":"name","label":"name","placeholder":"Выбрать вышестоящий документ СГП","options":_vm.parentProgramDict,"searchable":true,"allow-empty":true,"show-labels":false},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [(option.name!==undefined)?[(option.name.length<35)?_c('span',[_vm._v(_vm._s(option.name))]):_c('span',{attrs:{"title":option.name}},[_vm._v(_vm._s(option.name.slice(0, 35) + '...'))])]:_vm._e()]}}]),model:{value:(_vm.curSgpParentDoc),callback:function ($$v) {_vm.curSgpParentDoc=$$v},expression:"curSgpParentDoc"}})],1),_c('b-form-group',{attrs:{"label":"Документ СГП"}},[_c('multiselect',{attrs:{"track-by":"name","label":"name","placeholder":"Выбрать документ СГП","options":_vm.programDict,"searchable":true,"allow-empty":false,"show-labels":false},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [(option.name!==undefined)?[(option.name.length<35)?_c('span',[_vm._v(_vm._s(option.name))]):_c('span',{attrs:{"title":option.name}},[_vm._v(_vm._s(option.name.slice(0, 35) + '...'))])]:_vm._e()]}}]),model:{value:(_vm.curProgram),callback:function ($$v) {_vm.curProgram=$$v},expression:"curProgram"}})],1),_c('b-form-group',{attrs:{"label":"Направление"}},[_c('multiselect',{attrs:{"track-by":"name","label":"name","placeholder":"Выбрать направление","options":_vm.directDict,"searchable":true,"allow-empty":true,"show-labels":false},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [(option.name!==undefined)?[(option.name.length<35)?_c('span',[_vm._v(_vm._s(option.name))]):_c('span',{attrs:{"title":option.name}},[_vm._v(_vm._s(option.name.slice(0, 35) + '...'))])]:_vm._e()]}}]),model:{value:(_vm.curDirect),callback:function ($$v) {_vm.curDirect=$$v},expression:"curDirect"}})],1),_c('b-form-group',{attrs:{"label":"Цель"}},[_c('multiselect',{attrs:{"track-by":"name","label":"name","placeholder":"Выбрать цель","options":_vm.goalDict,"searchable":true,"allow-empty":true,"show-labels":false},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [(option.name!==undefined)?[(option.name.length<35)?_c('span',[_vm._v(_vm._s(option.name))]):_c('span',{attrs:{"title":option.name}},[_vm._v(_vm._s(option.name.slice(0, 35) + '...'))])]:_vm._e()]}}]),model:{value:(_vm.curGoal),callback:function ($$v) {_vm.curGoal=$$v},expression:"curGoal"}})],1),_c('b-form-group',{attrs:{"label":"Единица измерения показателя"}},[_c('multiselect',{attrs:{"track-by":"name","label":"name","placeholder":"Выбрать единицу измерения","options":_vm.unitDict,"searchable":true,"allow-empty":false,"show-labels":false},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [(option.name!==undefined)?[(option.name.length<35)?_c('span',[_vm._v(_vm._s(option.name))]):_c('span',{attrs:{"title":option.name}},[_vm._v(_vm._s(option.name.slice(0, 35) + '...'))])]:_vm._e()]}}]),model:{value:(_vm.curUnit),callback:function ($$v) {_vm.curUnit=$$v},expression:"curUnit"}})],1),_c('div',{staticClass:"forecast-block"},[_vm._l((_vm.years),function(year,yearIndx){return _c('div',{key:'yearIndx'+yearIndx,staticClass:"forecast-item"},[(_vm.years.length>0)?_c('b-form-group',{staticClass:"bolded no-line",attrs:{"label":"Значения показателя"}},[_c('b-input',{attrs:{"type":"number","min":"2000","max":"2100"},model:{value:(year.year),callback:function ($$v) {_vm.$set(year, "year", $$v)},expression:"year.year"}}),_c('b-input',{attrs:{"type":"text"},model:{value:(year.plan),callback:function ($$v) {_vm.$set(year, "plan", $$v)},expression:"year.plan"}})],1):_vm._e(),_c('b-form-group',{staticClass:"no-line",attrs:{"label":"Удалить"}},[_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.removeYears(yearIndx)}}},[_vm._v("-")])],1)],1)}),_c('b-form-group',{attrs:{"label":"Добавить значения"}},[_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.addYears}},[_vm._v("+")])],1)],2)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Населённый пункт"}},[_c('multiselect',{attrs:{"track-by":"name","label":"name","placeholder":"Выбрать населённый пункт","options":_vm.katoDict,"searchable":true,"allow-empty":false,"show-labels":false},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [(option.name!==undefined)?[(option.name.length<35)?_c('span',[_vm._v(_vm._s(option.name))]):_c('span',{attrs:{"title":option.name}},[_vm._v(_vm._s(option.name.slice(0, 35) + '...'))])]:_vm._e()]}}]),model:{value:(_vm.curKato),callback:function ($$v) {_vm.curKato=$$v},expression:"curKato"}})],1),_c('b-form-group',{attrs:{"label":"Ответственный исполнитель"}},[_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.executor),callback:function ($$v) {_vm.executor=$$v},expression:"executor"}})],1),_c('b-form-group',{staticClass:"no-line",attrs:{"label":"Показатель в СЭМ"}},[_c('multiselect',{attrs:{"track-by":"name","label":"name","placeholder":"Выбрать показатель СЭМ","options":_vm.smIndicDict,"searchable":true,"allow-empty":true,"show-labels":false},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [(option.name!==undefined)?[(option.name.length<35)?_c('span',[_vm._v(_vm._s(option.name))]):_c('span',{attrs:{"title":option.name}},[_vm._v(_vm._s(option.name.slice(0, 35) + '...'))])]:_vm._e()]}}]),model:{value:(_vm.curSmIndic),callback:function ($$v) {_vm.curSmIndic=$$v},expression:"curSmIndic"}})],1),_c('div',{staticClass:"forecast-block"},[_vm._l((_vm.abpItem),function(item,abpItemIndx){return _c('div',{key:'abpItemIndx'+abpItemIndx,staticClass:"forecast-item"},[_c('b-form-group',{staticClass:"bolded no-line",attrs:{"label":"АБП"}},[_c('b-form-select',{attrs:{"options":_vm.abpDict,"value-field":"id","text-field":"name"},on:{"change":function($event){return _vm.chgAbpbForm(abpItemIndx)}},model:{value:(item.curAbpId),callback:function ($$v) {_vm.$set(item, "curAbpId", $$v)},expression:"item.curAbpId"}})],1),_c('b-form-group',{staticClass:"bolded no-line",attrs:{"label":"Бюджетная программа"}},[_c('b-form-select',{attrs:{"options":item.bpList,"value-field":"id","text-field":"name"},on:{"change":function($event){return _vm.chgBpBform(abpItemIndx)}},model:{value:(item.curBpId),callback:function ($$v) {_vm.$set(item, "curBpId", $$v)},expression:"item.curBpId"}})],1),_c('b-form-group',{staticClass:"no-line",attrs:{"label":"Удалить"}},[_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.removeAbpBp(abpItemIndx)}}},[_vm._v("-")])],1)],1)}),_c('b-form-group',{attrs:{"label":"Добавить АБП/БП:"}},[_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.addAbpBp}},[_vm._v("+")])],1)],2)],1)],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"left-content"},[_c('p',{staticClass:"title"},[_vm._v("Добавить/редактировать")])])}]

export { render, staticRenderFns }