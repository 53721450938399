








































































































































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';

interface IAbpItem {
    curAbp: any | null;
    bpList: any[]; // список отфильтрованный без повторов
    bpAllList: any[]; // списко всех программ для данной АБП
    curBp: any | null;
    curAbpId: null | number; // временно вместо мультиселект
    curBpId: null | number; // временно вместо мультиселект
}

interface IYears {
    year: number;
    plan: number | string;
}

@Component({
    name: 'c-forecast-edit'
})
export default class CForecastEdit extends Vue {
    @Prop({
        required: true,
        default: null
    })
    private currentForecast!: any;

    private curId: null | number = null;

    private years: IYears[] = [];

    private dicts: any = {
        indicator: { name: 'Показатель', url: '/api/dict/indicator', items: [] },
        program: { name: 'Документ СГП', url: '/api/dict/program', items: [] },
        direction: { name: 'Направление', url: '/api/dict/direction', items: [] },
        sphere: { name: 'Сфера', url: '/api/dict/sphere', items: [] },
        goals: { name: 'Цели', url: '/api/dict/program_goals', items: [] },
        // unit: { name: 'Единица измерения', url: '/api/dict/unit', items: [] },
        statKato: { name: 'Като', url: '/api/stat-dict/kato', items: [] },
        statUnit: { name: 'Ед. изм. из стат', url: '/api/stat-dict/unit', items: [] },
        abpList: { name: 'АБП', url: '/api/forecast/dict-ebk-func-abp', items: [] },
        ebkFunc: { name: 'БП', url: '/api/forecast/dict-ebk-func-prg', items: [] }
    };

    private abpItem: IAbpItem[] = [];

    private smData = { name: 'Показатель из СЭМ', url: '/api/sm/data?source=СЭМ', items: [] };

    // -- макроиндикатор
    private macroindicator = false;

    // -- показатель,
    private curIndic: any = null;

    private get indicatorDict() {
        const result = [];
        for (const el of this.dicts.indicator.items) { result.push(this.setNameLang(el)); }
        return result;
    }
    // --- документ СГП
    private curProgram: any = null;

    private get programDict() {
        const result = [];
        for (const el of this.dicts.program.items) {
            if (!this.curSgpParentDoc || this.curSgpParentDoc.code !== el.code) { result.push(this.setNameLang(el)); }
        }
        return result;
    }

    // -- вышестоящий док. СГП
    private curSgpParentDoc: any = null;

    private get parentProgramDict() {
        const result = [];
        for (const el of this.dicts.program.items) {
            if (!this.curProgram || this.curProgram.code !== el.code) {
                result.push(this.setNameLang(el));
            }
        }
        return result;
    }
    // ---ед измерения
    private curUnit: any = null;

    private get unitDict() {
        const result = [];
        for (const el of this.dicts.statUnit.items) { result.push(this.setNameLang(el)); }
        return result;
    }
    // -- направление
    private curDirect: any = null;

    private get directDict() {
        const result = [];
        for (const el of this.dicts.direction.items) { result.push(this.setNameLang(el)); }
        return result;
    }
    // ----- сфера
    private curSphere: any = null;

    private get sphereDict() {
        const result = [];
        for (const el of this.dicts.sphere.items) { result.push(this.setNameLang(el)); }
        return result;
    }
    // --- цель
    private curGoal: any = null;

    private get goalDict() {
        const result = [];
        for (const el of this.dicts.goals.items) { result.push(this.setNameLang(el)); }
        return result;
    }
    // --абп
    private get abpDict() {
        const result = [];
        for (const el of this.dicts.abpList.items) {
            result.push(this.setNameLang(el, 'abp'));
        }
        return result;
    }

    // --бп
    private get bpDict() {
        const result = [];
        for (const el of this.dicts.ebkFunc.items) {
            result.push(this.setNameLang(el, 'prg'));
        }
        return result;
    }
    // --като
    private curKato: any = null;

    private get katoDict() {
        const result = [];
        for (const el of this.dicts.statKato.items) { result.push(this.setNameLang(el, 'code')); }
        return result;
    }
    // --показатель из СЭМ
    private curSmIndic: any = null;

    private get smIndicDict() {
        const result = [];
        for (const el of this.smData.items) { result.push(this.setNameLang(el, 'code')); }
        return result;
    }

    // ---- наименование целевого индикатора по документы СГП
    private indicatorCustomNameRu = ''; // на русском
    private indicatorCustomNameKz = '';  // на казахском

    // --- ответственный испольнитель
    private executor = '';



    private async created() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        this.loadDict(this.dicts.indicator);
        this.loadDict(this.dicts.program);
        this.loadDict(this.dicts.direction);
        this.loadDict(this.dicts.sphere);
        this.loadDict(this.dicts.goals);
        // this.loadDict(this.dicts.unit);
        this.loadDict(this.dicts.statKato);
        this.loadDict(this.dicts.statUnit);
        await this.loadDict(this.dicts.abpList);
        await this.loadDict(this.dicts.ebkFunc);
        this.loadData(this.smData);

        // ------
        this.abpItem = [];
        this.setFilter();
        this.$watch('currentForecast', that.setFilter);
    }

    // ---- загрузка

    private async loadDict(dict: any) {
        await fetch(dict.url).then(response => response.json()).then(json => {
            dict.items = json;
        });
    }

    private loadData(d: any) {
        fetch(d.url).then(response => response.json())
            .then(json => {
                json.forEach((item: any) => {
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    if (item.indicatorName) { item.name_ru = item.indicatorName; }
                    if (item.indicator) {
                        // eslint-disable-next-line @typescript-eslint/camelcase
                        if (item.indicator.name_ru) {
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            item.name_ru = item.indicator.name_ru;
                            item.indicatorName = item.indicator.name_ru;
                        }
                        // eslint-disable-next-line @typescript-eslint/camelcase
                        if (item.indicator.name_kk) { item.name_kk = item.indicator.name_kk; }
                    }
                });
                d.items = json;
            });
    }
    // -------------

    private setNameLang(obj: any, codeName?: any | null): any {
        if (obj === null) { return null; }
        let txt = obj['name_' + this.$i18n.locale];
        const newObj = Object.assign({}, obj);
        // eslint-disable-next-line @typescript-eslint/camelcase
        if (newObj.nameRu) { newObj.name_ru = newObj.nameRu; }
        // eslint-disable-next-line @typescript-eslint/camelcase
        if (newObj.nameKk) { newObj.name_kk = newObj.nameKk; }
        if (txt === undefined) { txt = newObj.name_ru; }
        if (codeName !== undefined && codeName !== null && newObj[codeName] !== undefined) {
            txt = newObj[codeName] + ' - ' + txt;
        }
        newObj.name = txt;
        return newObj;
    }

    private selectAbp(abpItem: IAbpItem, indx?: number) {
        abpItem.bpList = [];
        if (abpItem.curAbp === null || this.bpDict === null) {
            abpItem.curAbpId = null;
            return;
        }
        abpItem.curAbpId = abpItem.curAbp.id; // временно мультиселект
        let fl = false;
        if (abpItem.curBp === null) { fl = true; }
        abpItem.bpAllList = this.bpDict.filter(item => {
            if (abpItem.curAbp.abp === item.abp && abpItem.curBp !== null && item.id === abpItem.curBp.id) { fl = true; }
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            return abpItem.curAbp.abp === item.abp;
        });
        if (indx) {
            this.getBpList(indx);
        } else {
            abpItem.bpList = abpItem.bpAllList;
        }
        if (!fl) {
            abpItem.curBp = null;
            abpItem.curBpId = null; // временно мультиселект
        }
    }

    private getBpList(indx: number) { // фильтр чтобы не было дублей по программам
        this.abpItem[indx].bpList = this.abpItem[indx].bpAllList.filter(item => {
            let doubleEl = false;
            for (let i = 0; i < this.abpItem.length; i++) {
                const el = this.abpItem[i];
                if (i !== indx && el.curAbp.abp === item.abp && el.curBp && el.curBp.prg === item.prg) {
                    doubleEl = true;
                    break;
                }
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            return !doubleEl;
        });
    }

    private addAbpBp() {
        this.abpItem.push({ bpList: [], bpAllList: [], curBp: null, curAbp: null, curAbpId: null, curBpId: null });
    }

    private removeAbpBp(indx: number) {
        this.abpItem.splice(indx, 1);
        for (let i = 0; i < this.abpItem.length; i++) {
            this.getBpList(i);
        }
    }

    private addYears() {
        let year = new Date().getFullYear();
        if (this.years.length > 0) { year = this.years[this.years.length - 1].year + 1; }
        this.years.push({ year: year, plan: 0 });
    }

    private removeYears(indx: number) {
        this.years.splice(indx, 1);
    }

    private setFilter() {
        const currentForecast = this.currentForecast;
        console.log('currentForecast: ' + JSON.stringify(currentForecast));
        this.curSmIndic = null;
        if (currentForecast === null) {
            this.curId = null;
            this.curIndic = null;
            this.curUnit = null;
            this.curProgram = null;
            this.curDirect = null;
            this.curSphere = null;
            this.curGoal = null;
            this.indicatorCustomNameRu = '';
            this.indicatorCustomNameKz = '';
            this.curKato = null;
            this.executor = '';
            this.abpItem = [];
            this.years = [];
            this.curSgpParentDoc = null;
            this.macroindicator = false;
        } else {
            this.curId = currentForecast.id;
            this.macroindicator = currentForecast.macroindicator;
            this.curIndic = this.setNameLang(currentForecast.indicator);
            this.curUnit = this.setNameLang(currentForecast.statUnit);
            this.curProgram = this.setNameLang(currentForecast.program);
            this.curSgpParentDoc = this.setNameLang(currentForecast.sgpParentDoc);
            this.curDirect = this.setNameLang(currentForecast.direction);
            this.curGoal = this.setNameLang(currentForecast.goals);
            if (currentForecast.indicatorCustomName !== null) { this.indicatorCustomNameRu = currentForecast.indicatorCustomName; } else { this.indicatorCustomNameRu = ''; }
            if (currentForecast.indicatorCustomNameKz !== null) { this.indicatorCustomNameKz = currentForecast.indicatorCustomNameKz; } else { this.indicatorCustomNameKz = ''; }
            this.curKato = this.setNameLang(currentForecast.statKato);
            if (currentForecast.executor !== null) { this.executor = currentForecast.executor; } else { this.executor = ''; }
            if (currentForecast.smData) { this.curSmIndic = Object.assign({ name: currentForecast.smData.indicatorName }, currentForecast.smData); }
            // -------------- abpItem
            this.abpItem = [];
            if (currentForecast.abps && currentForecast.abps.length > 0) {
                this.abpItem = currentForecast.abps.map((item: any) => {
                    const abpSelect: IAbpItem = { curAbp: null, curBp: null, bpList: [], bpAllList: [], curAbpId: null, curBpId: null };
                    // eslint-disable-next-line @typescript-eslint/prefer-for-of
                    for (let i = 0; i < this.abpDict.length; i++) {
                        if (this.abpDict[i].abp === item.abp) {
                            abpSelect.curAbp = this.abpDict[i];
                            break;
                        }
                    }
                    this.selectAbp(abpSelect);
                    // eslint-disable-next-line @typescript-eslint/prefer-for-of
                    for (let i = 0; i < abpSelect.bpList.length; i++) {
                        const itemEbk = abpSelect.bpList[i];
                        if (itemEbk.abp === item.abp && itemEbk.gr === item.gr && itemEbk.pgr === item.pgr && itemEbk.ppr === item.ppr && itemEbk.prg === item.prg) {
                            abpSelect.curBp = itemEbk;
                            if (itemEbk !== null) { abpSelect.curBpId = itemEbk.id; }
                            break;
                        }
                    }

                    return abpSelect;
                });
                for (let i = 0; i < this.abpItem.length; i++) { this.getBpList(i); }
            }
            // -----------
            this.years = [];
            if (currentForecast.years && currentForecast.years.length) {
                this.years = currentForecast.years.map((item: any) => { return { year: item.year, plan: (item.plan ? item.plan : 0) }; });
            }
        }
    }

    private async save(close?: boolean) {
        let obj: any = null;
        if (this.currentForecast !== null) {
            obj = Object.assign({}, this.currentForecast);
        } else {
            // eslint-disable-next-line @typescript-eslint/camelcase
            obj = { id: this.curId, indicator: null, program: null, direction: null, sphere: null, goals: null, unit: null, statKato: null, statUnit: null, executor: null, years: [], abpsSelect: [], abps: [], statSetting: null, coefficient: null, arithmetic_operation: null, sgpParentDoc: null, macroindicator: null };
        }
        const method = obj.id ? 'PUT' : 'POST';
        // ----
        obj.indicator = this.curIndic;
        obj.statUnit = this.curUnit;
        obj.program = this.curProgram;
        obj.direction = this.curDirect;
        obj.goals = this.curGoal;
        obj.indicatorCustomName = this.indicatorCustomNameRu.trim().length > 0 ? this.indicatorCustomNameRu.trim() : null;
        obj.indicatorCustomNameKz = this.indicatorCustomNameKz.trim().length > 0 ? this.indicatorCustomNameKz.trim() : null;
        obj.statKato = this.curKato;
        obj.executor = this.executor;
        obj.smData = this.curSmIndic;
        obj.sgpParentDoc = this.curSgpParentDoc;
        obj.macroindicator = this.macroindicator;

        obj.years = [];
        for (let j = 0; j < this.years.length; j++) {
            const el = this.years[j];
            const res = { year: parseInt(el.year.toString()), plan: parseFloat(el.plan.toString()) };
            let fl = false;
            for (let i = 0; i < this.years.length; i++) {
                if (parseInt(this.years[i].year.toString()) === parseInt(el.year.toString()) && i !== j) {
                    fl = true;
                    break;
                }
            }
            if (fl) {
                this.makeToast('danger', 'Сохранение', 'Дубли по годам!');
                return;
            }
            if (res.plan) { obj.years.push(res); }
        }
        // ----
        if (!obj.indicator || !obj.program || !obj.statKato || !obj.statUnit || !obj.years.length) {
            this.makeToast('danger', 'Сохранение', 'Не заполнены обязательные поля!');
            return;
        }
        obj.abps = this.abpItem
            .filter(item => item.curBp && item.curBp.id)
            .map(item => {
                return {
                    gr: item.curBp.gr,
                    pgr: item.curBp.pgr,
                    abp: item.curBp.abp,
                    prg: item.curBp.prg,
                    ppr: item.curBp.ppr
                };
            });

        let result: any = await fetch('/api/forecast',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: method,
                body: JSON.stringify(obj)
            });
        if (result.status === 200) {
            result = await result.json();
            this.makeToast('success', 'Сохранение', 'Данные сохранены!');
            if (result.id) { this.curId = result.id; }
            this.$emit('saved', result);
            if (close) { this.closeEdit(); }
        } else {
            this.makeToast('danger', 'Сохранение', `Ошибка ${result.status} ${result.statusText}`);
        }
    }

    private makeToast(variant: any, title: string, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    private closeEdit() {
        this.curId = null;
        this.curIndic = null;
        this.curUnit = null;
        this.curProgram = null;
        this.curDirect = null;
        this.curSphere = null;
        this.curGoal = null;
        this.indicatorCustomNameRu = '';
        this.indicatorCustomNameKz = '';
        this.curKato = null;
        this.executor = '';
        this.abpItem = [];
        this.years = [];
        this.curSgpParentDoc = null;
        this.macroindicator = false;
        this.curSmIndic = null;
        this.$emit('closeEdit', true);
    }


    // ------------------ временно вместо multiselect
    private chgAbpbForm(indx: number) {
        const item = this.abpItem[indx];
        if (item.curAbpId === null) {
            item.curAbp = null;
            this.selectAbp(item, indx);
            return;
        }
        for (const el of this.abpDict) {
            if (el.id === item.curAbpId) {
                item.curAbp = el;
                this.selectAbp(item, indx);
                return;
            }
        }
    }

    private chgBpBform(indx: number) {
        const item = this.abpItem[indx];
        if (item.curBpId === null) {
            item.curBp = null;
            for (let i = 0; i < this.abpItem.length; i++) {
                if (i !== indx) { this.getBpList(i); }
            }

            return;
        }
        for (const el of item.bpList) {
            if (el.id === item.curBpId) {
                item.curBp = el;
                for (let i = 0; i < this.abpItem.length; i++) {
                    if (i !== indx) { this.getBpList(i); }
                }
                return;
            }
        }
    }
}
